
import Vue from 'vue'
import { pagingBuilder, sortingBuilder } from 'kwik-vue/utils/tables'
import { ActiveFilterItem, IHeaderOption, IPagingInput, ISortingInput, KwikMediumContainer, KwikPageToolbar, KwikTableAction, KwikTooltipButtonIcon, KwikDialog } from 'kwik-vue'
import { ICustomerTransaction, ICustomerTransactionGetInput } from '../types'
import { dispatchGetCustomerTransactionsCurrentPage, dispatchRecallAndReturnTransaction, dispatchRecallTransaction } from '../store/actions'
import { readCustomerTransactionsCount, readCustomerTransactionsCurrentPage } from '../store/getters'
import { RouteNames } from '@/router/names'
import { FilterOption } from '@/components/filtersBar/FilterOptions'
import { FilterTypes } from '@/components/filtersBar/FilterTypes'
import FiltersBar from '@/components/filtersBar/FiltersBar.vue'

export default Vue.extend({
  name: 'FFCustomerTransactions',
  components: { FiltersBar, KwikMediumContainer, KwikPageToolbar, KwikTableAction, KwikTooltipButtonIcon, KwikDialog },
  watch: {
    options: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    },
    filters: {
      async handler () {
        await this.loadPage()
      },
      deep: true
    }
  },
  data () {
    return {
      options: {} as any,
      loading: true,
      filters: [] as ActiveFilterItem[],
      showFilters: true,
      showRecall: false,
      showConfirm: false,
      currentOperationName: '',
      currentCustomerTransaction: null as ICustomerTransaction | null,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Transazioni clienti', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'ID',
          sortable: true,
          value: 'id',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Cliente',
          sortable: true,
          value: 'customer.business_name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Stato',
          sortable: true,
          value: 'status',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Saldato',
          sortable: true,
          value: 'payed_amount',
          hidden: false,
          align: 'start'
        },
        {
          text: this.$t('commons.buttons.actions'),
          value: 'actions',
          sortable: false,
          hidden: false
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    customerTransactionsItems () {
      return readCustomerTransactionsCurrentPage(this.$store)
    },
    total () {
      return readCustomerTransactionsCount(this.$store)
    },
    filterOptions (): FilterOption[] {
      return [
        {
          key: 'customer_id',
          label: 'Cliente',
          field: 'customer_id',
          type: FilterTypes.CUSTOMER
        },
        {
          key: 'status',
          label: 'Stato',
          field: 'status',
          type: FilterTypes.CUSTOMER_TRANSACTION_STATE
        }
      ] as FilterOption[]
    }
  },
  methods: {
    async loadPage () {
      this.loading = true
      const paging: IPagingInput = pagingBuilder(this.options)
      const sorting: ISortingInput = sortingBuilder(this.options)
      let customerId = null
      let status = null
      if (this.filters) {
        this.filters.forEach((filter) => {
          switch (filter.field) {
            case 'customer_id':
              customerId = filter.value
              break
            case 'status':
              status = filter.value
              break
            default:
            // nothing
          }
        })
      }
      const payload: ICustomerTransactionGetInput = {
        paging: paging,
        sorting: sorting,
        customer_id: customerId,
        status: status
      }
      await dispatchGetCustomerTransactionsCurrentPage(this.$store, payload)
      this.loading = false
    },
    async openDetail (id: string) {
      await this.$router.push(
        {
          name: RouteNames.FF_CUSTOMER_TRANSACTION_ROWS,
          params: { customer_transaction_id: id }
        }
      )
    },
    toggleFilter () {
      this.showFilters = !this.showFilters
    },
    filterData (activeFilters: ActiveFilterItem[]) {
      this.filters = activeFilters
    },
    async ship () {
      await this.$router.push(
        {
          name: RouteNames.FF_NEW_CUSTOMER_TRANSACTION
        }
      )
    },
    showRecallOption (item: ICustomerTransaction) {
      this.currentCustomerTransaction = item
      this.showRecall = true
    },
    closeRecall () {
      this.currentCustomerTransaction = null
      this.showRecall = false
    },
    getOperationText () {
      if (this.currentOperationName === 'DISPOSE') {
        return 'Confermi l\'operazione di richiamo sul lotto selezionato? NON sarà possibile annullazione l\'operazione e verranno addebitati i costi di smaltimento e una penale sulla vendita'
      } else {
        return 'Confermi l\'operazione di richiamo con reso sul lotto selezionato? NON sarà possibile annullazione l\'operazione e si otterrà un rimborso dal fornitore della materia prima se applicabile'
      }
    },
    startDisposeBatch () {
      this.showConfirm = true
      this.currentOperationName = 'DISPOSE'
    },
    startReturnBatch () {
      this.showConfirm = true
      this.currentOperationName = 'RETURN'
    },
    async confirmBatchActions () {
      if (!this.currentCustomerTransaction) return
      if (this.currentOperationName === 'DISPOSE') {
        await dispatchRecallTransaction(this.$store, this.currentCustomerTransaction.id)
      } else {
        // RETURN
        await dispatchRecallAndReturnTransaction(this.$store, this.currentCustomerTransaction.id)
      }
      this.loadPage()
      this.closeRecallActions()
    },
    closeRecallActions () {
      this.showRecall = false
      this.showConfirm = false
      this.currentOperationName = ''
      this.currentCustomerTransaction = null
    }
  }
})
